@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/skel';

/*
	Forty by Pixelarity
	pixelarity.com | hello@pixelarity.com
	License: pixelarity.com/license
*/

/* Button */

	.button {
		border: solid 2px !important;

		&.next {
			padding-right: 1.75em;

			&:before, &:after {
				display: none;
			}
		}
	}

/* Tiles */

	.tiles {
		article {
			width: 50%;
			-ms-behavior: url('assets/js/ie/backgroundsize.min.htc');
			background-size: cover;
		}
	}

/* Banner */

	#banner {
		-ms-behavior: url('assets/js/ie/backgroundsize.min.htc');

		&:after {
			display: none;
		}
	}

/* Menu */

	#menu {
		background: _palette(bg);
	}